import React from 'react';
import { BigNumber } from 'bignumber.js';
import { Trans } from '@lingui/macro';

import { AlertBanner } from '../../../ui-kit/AlertBanner';
import { DEFAULT_SLIPPAGE_OPTIONS } from '../../../ui-config/networksConfig';
import { SelectSlippage } from '../../../components-yldr/SelectSlippage';
import { useModalContext } from '../../../hooks/useModal';
import { EControlPositionType } from '../../../types/uniswapTokens';
import { ScrollModalContent, ScrollModalFooter } from '../../primitives/ScrollModal';
import { SLIPPAGE_WARNING } from '../constants';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { TxSuccessView } from '../FlowCommons/Success';
import { TxPositionDetails } from '../FlowCommons/TxPositionDetails';
import { DeleveragePositionActions } from './DeleveragePositionActions';

export const DeleveragePositionModalContent = React.memo(
  ({ enrichedPosition, positionAddress, isWrongNetwork, controlType }: PositionModalWrapperProps & { controlType: EControlPositionType }) => {
    const [slippage, setSlippage] = React.useState(DEFAULT_SLIPPAGE_OPTIONS[0]);
    const { gasLimit, txError, mainTxState, setTxError } = useModalContext();

    if (mainTxState.success)
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>Deleveraged position</Trans>}
            amount={enrichedPosition.marketReferenceCurrencyValue.toString()}
            symbol={`${enrichedPosition.token0.symbol} / ${enrichedPosition.token1.symbol}`}
          />
        </ScrollModalContent>
      );

    const deleveragePositionActionsProps = {
      marketId: enrichedPosition.marketId,
      slippage: BigNumber(slippage).multipliedBy(100).toNumber(),
      controlType,
      isWrongNetwork,
      positionAddress,
    };

    return (
      <>
        <ScrollModalContent>
          <TxPositionDetails token0={enrichedPosition.token0} token1={enrichedPosition.token1} />
          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
            bottomSlot={(
              <SelectSlippage defaultOptions={DEFAULT_SLIPPAGE_OPTIONS} value={slippage} onChange={setSlippage} />
            )}
          >
            <DetailsNumberLine
              description={<Trans>Total position liquidity</Trans>}
              value={enrichedPosition.marketReferenceCurrencyValue}
              numberPrefix='$'
            />
            {controlType === EControlPositionType.ManualPosition && (
              <DetailsNumberLine
                description={<Trans>Fees earned</Trans>}
                value={
                  enrichedPosition.token0.marketReferenceCurrencyFee + enrichedPosition.token1.marketReferenceCurrencyFee
                }
                numberPrefix="$"
              />
            )}
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}

        </ScrollModalContent>

        <ScrollModalFooter>
          <DeleveragePositionActions {...deleveragePositionActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
